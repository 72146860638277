.scroll {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  width: 869px;
  -ms-overflow-style {
    width: 0px;
  }

  ::-webkit-scrollbar {
    display: none;
    width: 6px;
    /* padding-right: 6px; */
  }
  ::-webkit-scrollbar-track {
    -webkit-box-: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    margin-right: 6px;
  }
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
